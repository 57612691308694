<template>
  <v-card class="pa-5">
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="Title"
      >
        <v-text-field
          v-model="value.title"
          outlined
          label="Title"
          required
          :error-messages="errors"
        />
      </validation-provider>
      <m-coord-picker
        v-model="value.coords"
        label="Weather Coordinates"
      />
      <field-card label="Design">
        <m-color-input
          v-model="value.backgroundColor"
          label="Background Color"
        />
        <m-color-input
          v-model="value.hotColor"
          label="Hot Temperature Color"
        />
        <m-color-input
          v-model="value.coolColor"
          label="Cool Temperature Color"
        />
        <v-row class="flex-wrap">
          <v-switch
            v-model="value.dark"
            class="mx-5"
            label="Dark Mode"
          />
        </v-row>
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MWeatherForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    layoutId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    error: null
  })
}
</script>
